import styled from 'styled-components';
import tw from 'twin.macro';

export const ContactInfoItem = styled.div`
  ${tw`bg-white w-full sm:w-1/3 m-auto text-center p-10 text-sm`};
`;

export const ContactInfoLogo = styled.div`
  ${tw`w-full m-auto`};
`;

export const Image = styled.img`
  ${tw`m-auto pb-8`};
`;

export const Bold = styled.b`
  ${tw`pb-8 block text-gray-500`};
`;

export const Track = styled.div`
  ${tw`mx-auto`};
  width: 544px;
  margin: 47px auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .bgWrap{
    padding: 32px 16px;
    ${tw`bg-white flow-root mx-auto`};
    .headerTrack{
      label{
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: #4D4545;
        margin: 4px 0px;
      }
      p{
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #8B9196;
        margin: 4px 0px;
      }
      span{
        font-size: 13px;
        line-height: 100%;
        display: flex;
        align-items: center;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #8B9196;
        margin-bottom: 4px 0 14px;
      }
    }
    .statusBlock{
      background: #F1F1F1;
      position: relative;

      display: flex;
      flex-direction: column;
      background: white;
      justify-content: center;
      align-items: center;

      img {
        width: 220px;
        height: 220px;
      }

      .statusText{
        width:100%;
        display: flex;
        align-content: center;
        justify-content: center;
        p{
          font-size: 13px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          color: #8B9196;
          padding: 0 9rem;
          margin-top: -50px;
          margin-bottom: 100px;
        }
      }

      &.mod-2 {
        img {
          width: 220px;
          height: 220px;
          margin-top: -120px;
        }
        .statusText{
          p{
            margin-top: -150px;
            margin-bottom: 0;
          }
        }
      }

      &.mod-5 {
        img {
          width: 220px;
          height: 220px;
        }
        .statusText{
          p{
            margin-top: 10px;
            margin-bottom: 20px;
          }
        }
      }

      .wrapSpinner{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 100%;
        .loader {
          border-top-color: #8B9196;
          -webkit-animation: spinner 1.5s linear infinite;
          animation: spinner 1.5s linear infinite;
        }

        @-webkit-keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

    }
    .orderDetails{
      ${tw`justify-between`};
      margin-top: 24px;
      .column{
        ${tw`flex-1 text-sm text-gray-700 text-left`};
        label{
          font-size: 12px;
          line-height: 100%;
          align-items: center;
          color: #383C3F;
          margin: 6px 0px;
          font-weight:500;
        }
        p{
          font-size: 13px;
          line-height: 120%;
          align-items: center;
          letter-spacing: 0.5px;
          color: #383C3F;
        }
        .group{
          margin: 16px 0;
          p{
            line-height: 120%;
          }
        }
        .address{
          ${tw`border border-gray-200`};
          padding: 16px;
          p{
            line-height: 100%;
          }
        }
      }
    }

    .totalWrap{
      ${tw`w-full`}
      padding: 8px 20px 20px 20px;
      .cart{
        ${tw`flex justify-between`}
        p{
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.05em;
          color: #9B9999;
        }
        span{
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.05em;
          font-feature-settings: 'tnum' on, 'lnum' on;
          color: #4D4545;
        }
      }
      .payment{
        p, span{
          font-size: 20px;
          line-height: 25px;
          display: flex;
          align-items: center;
          color: #383C3F;
          letter-spacing: inherit;
        }
      }
    }

    .orderDetails{
      .title{
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.03em;
        color: #000000;
      }
      .block{
        margin: 16px 0;
        p{
          font-size: 12px;
          line-height: 100%;
          display: flex;
          align-items: center;
          color: #8B9196;
          margin: 4px 0px;
        }
        span{
          font-size: 13px;
          line-height: 120%;
          display: flex;
          align-items: center;
          letter-spacing: 0.5px;
          color: #8B9196;
        }
      }
    }

    .itemsList{

      .title{
        ${tw`my-4`}
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.03em;
        color: #000000;
      }
      .items{
        ${tw`flex gap-3`}
        padding: 8px 20px 20px 20px;
        background: #FCFCFC;
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        align-content: center;
        justify-content: center;
        align-items: center;
        .imageWrap{
          ${tw`flex-shrink-0 w-14 h-14 border border-gray-200 rounded-md overflow-hidden`}
          img{
            ${tw`w-14 h-14 object-center object-contain`}
          }
        }
        .detailsWrap{
          ${tw`flex-grow`}
          p{
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.04em;
            color: #4D4545;
            margin-bottom: 0.5rem;
          }
          .qtyPrice{
            ${tw`flex justify-between w-full`}
            .qty{
              font-size: 11px;
              line-height: 14px;
              letter-spacing: 0.09em;
              color: #888D9A;
              background: #E1E6E7;
              border-radius: 4px;
              padding: 4px 8px;
            }
            .price{
              font-size: 12px;
              line-height: 15px;
              letter-spacing: 0.05em;
              font-feature-settings: 'tnum' on, 'lnum' on;
              color: #4D4545;
            }
          }
        }
      }
    }
  }
  .refund{
    padding: 0;
    ${tw`bg-white flow-root mx-auto`};
    .logo{
      background: #FCFCFC;
      padding: 25px 60px 27px;
    }
    .content{
      padding: 32px 60px 0;
      .title{
        margin: 4px 0px;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: #4D4545;
      }
      .msg{
        margin-bottom: 32px;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #8B9196;
      }
      .refunded{
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        p{
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.04em;
          color: #4D4545;
        }
      }
    }
  }
`;

export const WrapTrack = styled.div`
  .circleActive{
    ${tw`bg-yellowu-FED988`};
  }
  .circle{
    ${tw`flex mx-auto rounded-full text-lg text-white flex items-center`};
    width: 34px;
    height: 34px;
    align-content: center;
    justify-content: center;
  }
  .dot{
    ${tw`rounded-full text-white text-center w-full`};
    width: 14px;
    height: 14px;
    left: 10px;
    top: 10px;
  }
`;

export const Mobile = styled.div`
  ${tw`block sm:hidden`};
  margin: 16px 16px 0;
`;
