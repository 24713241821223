import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrap = styled.div`
  ${tw`w-full mx-auto cursor-pointer`};
  a{
    color: #0963CD;
    letter-spacing: 0.05em;
    ${tw`text-xs flex`};
    width: 100% !important;
    display: flex;
    align-items: center;
  }
`;
