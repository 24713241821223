import React, { useEffect, useState } from 'react';
import Tracker from './Tracker';
import { interval, of, Subject } from 'rxjs';
import { catchError, shareReplay, startWith, take, takeUntil } from 'rxjs/operators';
import { format, parseISO } from 'date-fns';
import { getOrderDetails } from '../../state/checkout/checkout.service';
import LoadingAnimation from './../../assets/images/loading-animation.gif'
import CheckedAnimation from './../../assets/images/checked-animation.gif'
import CarAnimation from './../../assets/images/car-animation.gif'
import * as Styled from './styles';
import { getQueryVariable, productImageUrl } from '../../state/utils';
import PropTypes from 'prop-types';
import GoBackLink from '../ui/GoBackLink';
import { graphql, useStaticQuery } from 'gatsby';

const TrackOrder = ({ locale, location }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiCheckoutPage_en: strapiCheckoutPage(locale: { eq: "en" }) {
        back_to_shop_link_text
      }
      strapiCheckoutPage_zh: strapiCheckoutPage(locale: { eq: "zh" }) {
        back_to_shop_link_text
      }
    }
  `);
  const trans = gql[`strapiCheckoutPage_${locale}`];
  const [unMount$] = useState(new Subject());
  const [order, setOrder] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [orderStatus, setOrderStatus] = useState(1);
  const orderNumber = getQueryVariable('order_number', location);
  const source$ = interval(6500);

  useEffect(() => {
    source$.pipe(takeUntil(unMount$), startWith(0), shareReplay()).subscribe(() => {
      getOrderDetails(orderNumber)
        .pipe(
          take(1),
          catchError((err) => {
            return of(err);
          })
        )
        .subscribe((orderDetails) => {
          setOrder(orderDetails);
          setOrderStatus(orderDetails.status);
          setCoupon(orderDetails.coupon);
        });
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  const translations = {
    en: {
      est_time_of_delivery: 'Estimated time of delivery',
      shipping_to: 'Shipping to',
      note_to_driver: 'Instruction for driver',
      contact_number: 'Contact number',
      cancelled_order: 'Your order has been cancelled',
      refund_title: 'Order ' + order?.order_number + ' has been refunded',
      refund_msg: 'Please allow up to 7-10 business days for the refund to appear in your account.',
      refund_amt: 'Refunded Amount',
      refund_regards: 'Regards',
      refund_powered: 'Powered by',
    },
    zh: {
      est_time_of_delivery: '预计交货时间',
      shipping_to: '运到',
      note_to_driver: '司机须知',
      contact_number: '联系电话',
      cancelled_order: '您的订单已被取消',
      refund_title: '订单 ' + order?.order_number + ' 已退款',
      refund_msg: '退款最多需要 7-10 个工作日才能显示在您的帐户中。',
      refund_amt: '退款金额',
      refund_regards: '问候',
      refund_powered: '供电',
    },
  };

  const getCardHeading = () => {
    if (locale === 'en') {
      switch (orderStatus) {
        case 1: // pending
          return `Confirming your Order`;
        case 2: // confirmed
          return `Your order has been confirmed`;
        case 3: // order ready
          return `Your order has been confirmed`;
        case 4: // in transit
          return `Your order has been collected`;
        case 5: // delivered
          return `Your order has been delivered`;
        case 6: // void or cancelled
          return `Your order has been cancelled`;
        case 7: // refunded
          return `Your order has been refunded`;
        default:
          return 'Unknown order status';
      }
    } else {
      switch (orderStatus) {
        case 1:
          return `确认您的订单`;
        case 2:
          return `您的订单已确认`;
        case 3:
          return `您的订单已确认`;
        case 4:
          return `您的订单已被收集`;
        case 5:
          return `您的订单已送达`;
        case 6:
          return `您的订单已被取消`;
        case 7:
          return `您的订单已退款`;
        default:
          return 'Unknown order status';
      }
    }
  };

  const getStatus = () => {
    if (locale === 'en') {
      switch (orderStatus) {
        case 1:
          return `Please wait while we confirm your order`;
        case 2:
          return `We'll update you once your order has been collected`;
        case 3:
          return `We'll update you once your order has been collected`;
        case 4:
          return `Sit tight your order is on its way!`;
        case 5:
          return `Thank you for ordering with Ubottle`;
        default:
          return 'Unknown Value';
      }
    } else {
      switch (orderStatus) {
        case 1:
          return `我们正在确认您的订单，请稍候`;
        case 2:
          return `我们会在收到您的订单后通知您`;
        case 3:
          return `我们会在收到您的订单后通知您`;
        case 4:
          return `请耐心等待您的订单正在发送中！`;
        case 5:
          return `感谢您通过 Ubottle 订购`;
        default:
          return 'Unknown Value';
      }
    }
  };

  const formatDate = (date) => {
    if (!date) {
      return '';
    }

    try {
      if (typeof date === 'object') {
        return format(date, 'PPpp');
      }

      if (typeof date === 'string') {
        return format(parseISO(date), 'PPpp');
      }
      return format(date, 'PPpp');
    } catch (e) {
      return format(parseISO(date), 'PPpp');
    }
  };

  return (
    <Styled.Track>
      {orderStatus === 6 || orderStatus === 7 ? (
        <></>
      ) : (
        <div className="w-full mx-auto my-4 pb-4 flow-root">
          <Tracker orderStatus={orderStatus} />
        </div>
      )}

      {orderStatus === 7 && (
        <>
          <div className="bgWrap refund">
            <div className="headerTrack pb-6">
              <div className="content">
                <p className="title">{translations[locale]?.refund_title}</p>
                <p className="msg">{translations[locale]?.refund_msg}</p>
                <div className="refunded">
                  <p>{translations[locale]?.refund_amt}</p>
                  <p>${parseFloat(order?.refund_total).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {orderStatus === 6 && (
        <>
          <div className="bgWrap">
            <div className="headerTrack pb-6">
              <label>{getCardHeading()}</label>
              <p>
                Order {order?.order_number} was cancelled at your request and <br /> your payment
                has been voided.
              </p>
            </div>
            <hr />
            <div className="itemsList">
              <p className="title">Ordered Items:</p>
              {order?.payments[0]?.items?.map((item, index) => (
                <div className="items" key={index}>
                  <div className="imageWrap">
                    <img
                      src={
                        item.img_path === null
                          ? `https://via.placeholder.com/254x320/fff/333`
                          : productImageUrl(item.img_path)
                      }
                      alt={item.product_name}
                    />
                  </div>
                  <div className="detailsWrap">
                    <p>{locale === 'en' ? item.product_name : item.product_name_cn}</p>
                    <div className="qtyPrice">
                      <span className="qty">
                        {item.qty} x {locale === 'en' ? item.each_uom : item.each_uom_cn}
                      </span>
                      <span className="price">${item.price_each}</span>
                    </div>
                  </div>
                </div>
              ))}
              <div className="totalWrap">
                <div className="cart">
                  <p>Cart Total</p>
                  <span>${order?.amount}</span>
                </div>
                <div className="cart">
                  <p>Delivery Fee</p>
                  <span>${order?.delivery_fee}</span>
                </div>
                {!!order.small_order_fee && (
                  <div className="cart">
                    <p>Small Order Fee</p>
                    <span>${order.small_order_fee}</span>
                  </div>
                )}
                {coupon && (
                  <div className="cart">
                    <p>Discount Coupon {coupon.code}</p>
                    <span>-${parseFloat(coupon.applied_value).toFixed(2)}</span>
                  </div>
                )}
              </div>
              <hr />
              <div className="totalWrap">
                <div className="cart payment">
                  <p>Total Payment</p>
                  <span>${parseFloat((+order?.amount + +order?.delivery_fee + +order?.small_order_fee - (coupon ? +coupon.applied_value : 0))).toFixed(2)} </span>
                </div>
              </div>
              <hr />

              <div className="orderDetails">
                <p className="title">Order Details:</p>
                <div className="block">
                  <p>Delivery Address</p>
                  <span>{order?.customer_address || ''}</span>
                </div>

                <div className="block">
                  <p>Contact Number</p>
                  <span>{order?.customer_phone || ''}</span>
                </div>

                <div className="block">
                  <p>Note to Driver</p>
                  <span>{order?.special_instructions}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {orderStatus === 5 && (
        <>
          <div className="bgWrap">
            <div className="headerTrack">
              <label>{getCardHeading()}</label>
              <p>{order?.customer_address || ''}</p>
              <span>
                {translations[locale]?.est_time_of_delivery} &nbsp;{' '}
                {!!order?.delivery_due_date && <>{formatDate(order.delivery_due_date)}</>}
              </span>
            </div>
            <div className="statusBlock text-center mt-6 mod-5">
              <img src={CheckedAnimation} alt="CheckedAnimation"/>
              <div className="statusText">
                <p>{getStatus()}</p>
              </div>
            </div>
          </div>
          <br/>
          <div className="bgWrap" style={{paddingTop: '0.5rem'}}>
            <div className="itemsList">
              <p className="title">Ordered Summary</p>
              {order?.payments[0]?.items?.map((item, index) => (
                <div className="items" key={index}>
                  <div className="imageWrap">
                    <img
                      src={
                        item.img_path === null
                          ? `https://via.placeholder.com/254x320/fff/333`
                          : productImageUrl(item.img_path)
                      }
                      alt={item.product_name}
                    />
                  </div>
                  <div className="detailsWrap">
                    <p>{locale === 'en' ? item.product_name : item.product_name_cn}</p>
                    <div className="qtyPrice">
                      <span className="qty">
                        {item.qty} x {locale === 'en' ? item.each_uom : item.each_uom_cn}
                      </span>
                      <span className="price">${item.price_each}</span>
                    </div>
                  </div>
                </div>
              ))}
              <div className="totalWrap">
                <div className="cart">
                  <p>Cart Total</p>
                  <span>${order?.amount}</span>
                </div>
                <div className="cart">
                  <p>Delivery Fee</p>
                  <span>${order?.delivery_fee}</span>
                </div>
                {!!order.small_order_fee && (
                  <div className="cart">
                    <p>Small Order Fee</p>
                    <span>${order.small_order_fee}</span>
                  </div>
                )}
                {coupon && (
                  <div className="cart">
                    <p>Discount Coupon {coupon.code}</p>
                    <span>-${parseFloat(coupon.applied_value).toFixed(2)}</span>
                  </div>
                )}
              </div>
              <hr />
              <div className="totalWrap">
                <div className="cart payment">
                  <p>Total Payment</p>
                  <span>${parseFloat((+order?.amount + +order?.delivery_fee + +order?.small_order_fee - (coupon ? +coupon.applied_value : 0))).toFixed(2)} </span>
                </div>
              </div>
              <hr />

              <div className="orderDetails">
                <p className="title">Order Details:</p>
                <div className="block">
                  <p>Delivery Address</p>
                  <span>{order?.customer_address || ''}</span>
                </div>

                <div className="block">
                  <p>Contact Number</p>
                  <span>{order?.customer_phone || ''}</span>
                </div>

                <div className="block">
                  <p>Note to Driver</p>
                  <span>{order?.special_instructions}</span>
                </div>
              </div>
            </div>

            <div className="orderDetails">
              <div className="column">
                <div className="address">
                  <label>{translations[locale]?.note_to_driver}</label>
                  <p>{!!order?.special_instructions && <p>{order.special_instructions}</p>}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {(orderStatus === 2 || orderStatus === 3) && (
        <>
          <div className="bgWrap" style={{'overflow': 'hidden', 'height': '145px'}}>
            <div className="statusBlock text-center mt-6 mod-2">
              <img src={LoadingAnimation} alt="LoadingAnimation"/>
              <div className="statusText">
                <p>{getStatus()}</p>
              </div>
            </div>
          </div>
          <br/>
          <div className="bgWrap">
            <div className="headerTrack">
              <label>{getCardHeading()}</label>
              <p>Order #{order?.order_number || ''}</p>
            </div>

            <br/>
            <hr/>

            <div className="itemsList">
              <p className="title">Ordered Items:</p>
              {order?.payments[0]?.items?.map((item, index) => (
                <div className="items" key={index}>
                  <div className="imageWrap">
                    <img
                      src={
                        item.img_path === null
                          ? `https://via.placeholder.com/254x320/fff/333`
                          : productImageUrl(item.img_path)
                      }
                      alt={item.product_name}
                    />
                  </div>
                  <div className="detailsWrap">
                    <p>{locale === 'en' ? item.product_name : item.product_name_cn}</p>
                    <div className="qtyPrice">
                      <span className="qty">
                        {item.qty} x {locale === 'en' ? item.each_uom : item.each_uom_cn}
                      </span>
                      <span className="price">${item.price_each}</span>
                    </div>
                  </div>
                </div>
              ))}
              <div className="totalWrap">
                <div className="cart">
                  <p>Cart Total</p>
                  <span>${order?.amount}</span>
                </div>
                <div className="cart">
                  <p>Delivery Fee</p>
                  <span>${order?.delivery_fee}</span>
                </div>
                {!!order.small_order_fee && (
                  <div className="cart">
                    <p>Small Order Fee</p>
                    <span>${order.small_order_fee}</span>
                  </div>
                )}
                {coupon && (
                  <div className="cart">
                    <p>Discount Coupon {coupon.code}</p>
                    <span>-${parseFloat(coupon.applied_value).toFixed(2)}</span>
                  </div>
                )}
              </div>
              <hr />
              <div className="totalWrap">
                <div className="cart payment">
                  <p>Total Payment</p>
                  <span>${parseFloat((+order?.amount + +order?.delivery_fee + +order?.small_order_fee - (coupon ? +coupon.applied_value : 0))).toFixed(2)} </span>
                </div>
              </div>
              <hr />

              <div className="orderDetails">
                <p className="title">Order Details:</p>
                <div className="block">
                  <p>Delivery Address</p>
                  <span>{order?.customer_address || ''}</span>
                </div>

                <div className="block">
                  <p>Contact Number</p>
                  <span>{order?.customer_phone || ''}</span>
                </div>

                <div className="block">
                  <p>Note to Driver</p>
                  <span>{order?.special_instructions || 'N/A'}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {orderStatus === 1 && (
        <>
          <div className="bgWrap">
            <div className="headerTrack">
              <label>{getCardHeading()}</label>
              <p>{order?.customer_address || ''}</p>
              <span>
                {translations[locale]?.est_time_of_delivery} &nbsp;{' '}
                {!!order?.delivery_due_date && <>{formatDate(order.delivery_due_date)}</>}
              </span>
            </div>

            <div className="statusBlock text-center mt-6">
              <img src={LoadingAnimation} alt="LoadingAnimation"/>
              <div className="statusText">
                <p>{getStatus()}</p>
              </div>
            </div>

            <div className="orderDetails">
              <div className="column">
                <div className="address">
                  <label>{translations[locale]?.note_to_driver}</label>
                  <p>{!!order?.special_instructions && <p>{order.special_instructions}</p>}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {orderStatus === 4 && (
        <>
          <div className="bgWrap">
            <div className="headerTrack">
              <label>{getCardHeading()}</label>
              <p>{order?.customer_address || ''}</p>
              <span>
                {translations[locale]?.est_time_of_delivery} &nbsp;{' '}
                {!!order?.delivery_due_date && <>{formatDate(order.delivery_due_date)}</>}
              </span>
            </div>

            <div className="statusBlock text-center mt-6">
              <img src={CarAnimation} alt="CarAnimation"/>
              <div className="statusText">
                <p>{getStatus()}</p>
              </div>
            </div>

            <div className="orderDetails">
              <div className="column">
                <div className="address">
                  <label>{translations[locale]?.note_to_driver}</label>
                  <p>{!!order?.special_instructions && <p>{order.special_instructions}</p>}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="w-full m-auto text-center"></div>
      <Styled.Mobile>
        <GoBackLink title={trans.back_to_shop_link_text} link="/shop" />
      </Styled.Mobile>
    </Styled.Track>
  );
};

TrackOrder.propTypes = {
  locale: PropTypes.string,
};

TrackOrder.defaultProps = {
  locale: 'en',
};

export default TrackOrder;
