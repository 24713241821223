import React from 'react';
import * as Styled from './../styles'

const width = { width: `100%` };
const width0 = { width: `0` };

const Tracker = ({ orderStatus }) => {
  return (
    <Styled.WrapTrack>
      <div className="flex pb-3">
        <div className="flex-1"/>

        <div className="flex-2">
          <div className="circle circleActive">
            <span className="dot bg-yellowu-FCCC63">
              <i className="fa fa-check w-full fill-current white" />
            </span>
          </div>
        </div>

        <div className="w-1/6 align-center items-center align-middle content-center flex">
          <div className="w-full bg-yellow-300 items-center align-middle align-center flex-1">
            <div
              className="bg-yellowu-FED988 text-xs leading-none py-1 text-center text-grey-darkest "
              style={width}
            />
          </div>
        </div>

        <div className="flex-2">
          <div className={`circle ${ orderStatus > 2 ? 'bg-yellowu-FED988' : 'bg-grayu-C4C4C4'} `}>
            <span className={`dot ${ orderStatus > 2 ? 'bg-yellowu-FCCC63' : ''}`}>
              <i className="fa fa-check w-full fill-current white" />
            </span>
          </div>
        </div>

        <div className="w-1/6 align-center items-center align-middle content-center flex">
          <div className={`w-full ${ orderStatus > 2 ? 'bg-yellowu-FED988' : 'bg-grayu-C4C4C4'} items-center align-middle align-center flex-1`}>
            <div
              className="bg-grayu-C4C4C4 text-xs leading-none py-1 text-center text-grey-darkest "
              style={width0}
            />
          </div>
        </div>

        <div className="flex-2">
          <div className={`circle ${ orderStatus > 3 ? 'bg-yellowu-FED988' : 'bg-grayu-C4C4C4'}`}>
            <span className={`dot ${ orderStatus > 3 ? 'bg-yellowu-FCCC63' : ''}`} />
          </div>
        </div>

        <div className="w-1/6 align-center items-center align-middle content-center flex">
          <div className={`w-full ${ orderStatus > 3 ? 'bg-yellowu-FED988' : 'bg-grayu-C4C4C4'} items-center align-middle align-center flex-1`}>
            <div
              className="bg-grayu-C4C4C4 text-xs leading-none py-1 text-center text-grey-darkest "
              style={width0}
            />
          </div>
        </div>

        <div className="flex-2">
          <div className={`circle ${ orderStatus === 5 ? 'bg-yellowu-FED988' : 'bg-grayu-C4C4C4'} `}>
            <span className={`dot ${ orderStatus === 5 ? 'bg-yellowu-FCCC63' : ''}`} />
          </div>
        </div>

        <div className="flex-1"/>
      </div>
    </Styled.WrapTrack>
  );
};

export default Tracker;
