import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-i18next';

import * as Styled from './styles';
import Arrow from './../../ui/icons/arrowBack'

const GoBackLink = ({ title, link }) => (
  <Styled.Wrap className="backLink">
    <Link to={link}>
      <Arrow/>
      <label className='cursor-pointer'>{title}</label>
    </Link>
  </Styled.Wrap>
);

GoBackLink.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string
};

export default GoBackLink;
